
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from 'next-translate/useTranslation'
import { Section, Container, Heading, TextBlock, Main } from '@ui'
import { get } from 'utils/api'
import {useState, useEffect} from 'react'
import { useRouter } from 'next/router'

export default function Page(props) {
    const { t, lang } = useTranslation('common')
    const [locale, setLocale] = useState(lang)
    const [status, setStatus] = useState(1)
    const route = useRouter()
    let slug
    if (route && route.query && route.query.slug) {
        slug = route.query.slug[0]
    }

    useEffect(() => {
        const go = async () => {
            const response = await get('search-shipment', { s: slug })
            if (response.data && response.data.data && response.data.data.id) {
                route.push(`/${locale}/declare/1?s=${slug}`)
            } else {
                setStatus(2)
            }
        }

        go()
    },[])
    
    if (status !== 2) {
        return null
    }

    return (
        <Main className="bg-sand-light m:bg-white">
            <Section className="Section--text-page">
                <Container className="pt-13 m:pt-19 pb-20 m:pb-18">
                    <div className="mb-5 m:mb-12 m:-mx-6 m:py-6 m:px-6 m:bg-sand-light">
                        <Heading
                            text={t('404_title')}
                            className="m:hidden"
                            size="s"
                            component="h1"
                        />
                        <Heading
                            text={t('404_title')}
                            className="hidden m:block"
                            size="m"
                            component="h1"
                        />
                    </div>
                    <TextBlock className="mb-9 m:mb-12">
                        <p>{t('404_text')}</p>
                    </TextBlock>
                </Container>
            </Section>
        </Main>
    )
}

// export async function _getServerSideProps_(context) {
//     const locale = context.locale || 'lt'
//     const slug = context.params.slug[0]
//     const response = await get('search-shipment', { s: slug })
//     if (response.data && response.data.data && response.data.data.id) {
//         return {
//             redirect: {
//                 destination: `/${locale}/declare/1?s=${slug}`,
//                 permanent: false
//             }
//         }
//     }

//     return {
//         props: {}
//     }

//     // return {
//     //     redirect: {
//     //         destination: '/',
//     //         permanent: false
//     //     }
//     // }

//     // return { props: { slug } }
// }


    export async function getServerSideProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[...slug]',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  